<template>
  <generic-modal close-button :opened="open" @close="$emit('close')" size="lg">
    <template v-slot:header>
      <div class="modal-box__title">Заключение об аккредитационной экспертизе</div>
    </template>
    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--fourth">
                <input-text
                    label="Дата составления заключения"
                    v-model="form.createdAt"
                    :has-error="vuelidate.form.createdAt.$error"
                    :vuelidate-errors="vuelidate.form.createdAt.$errors"
                    is-date
                    button="calendar"
                />
              </div>
              <div class="col col--three-fourths">
                <input-text
                    label="Тип распорядительного акта"
                    :model-value="form.order.type"
                    is-disabled
                />
              </div>
              <div class="col col--full">
                <text-area
                    label="Полное наименование аккредитационного органа"
                    :model-value="form.order.issuedBy"
                    is-disabled
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="Дата распорядительного акта"
                    :model-value="this.$filters.formatDate(form.order.createdAt)"
                    button="calendar"
                    is-disabled
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="№ распорядительного акта"
                    :model-value="form.order.number"
                    is-disabled
                />
              </div>
              <div class="col col--full">
                <text-area
                    label="Полное наименование ОО"
                    :model-value="form.organization.name"
                    is-disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-box__block" v-for="(speciality, index) in form.specialities" :key="index">
        <div class="block">
          <div class="block__top">
            <div class="block-title">{{ speciality.code }} {{ speciality.name }}</div>
          </div>
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <input-text
                    label="Уровень образования"
                    :model-value="speciality.educationLevel"
                    is-disabled
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Наименование направления подготовки"
                    :model-value="speciality.name"
                    is-disabled
                />
              </div>
              <div class="col col--full">
                <input-text label="Название УГСН" :model-value="speciality.egs?.name" is-disabled />
              </div>
              <div class="col col--half">
                <input-text label="Код УГСН" :model-value="speciality.egs?.code" is-disabled />
              </div>
              <div class="col col--half">
                <input-text
                    label="Код направления подготовки"
                    :model-value="speciality.code"
                    is-disabled
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="Дата приказа об утверждении аккред. показателей"
                    v-model="speciality.accreditations.createdAt"
                    :has-error="vuelidate.form.specialities[index].accreditations.createdAt.$error"
                    :vuelidate-errors="vuelidate.form.specialities[index].accreditations.createdAt.$errors"
                    is-date
                    button="calendar"
                    placeholder="Выберите дату приказа"
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="№ приказа об утверждении аккред. показателей"
                    v-model="speciality.accreditations.number"
                    :has-error="vuelidate.form.specialities[index].accreditations.number.$error"
                    :vuelidate-errors="vuelidate.form.specialities[index].accreditations.number.$errors"
                />
              </div>
              <div class="col col--full">
                <custom-select
                    label="Соответствие ООП требованиям аккредитационных показателей"
                    v-model="speciality.accreditations.compliance"
                    :has-error="vuelidate.form.specialities[index].accreditations.compliance.$error"
                    :vuelidate-errors="vuelidate.form.specialities[index].accreditations.compliance.$errors"
                    :options="[{ value: 'Соответствует' }, { value: 'Не соответствует' }]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button label="Сформировать" @click="generateConclusion" />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import CustomSelect from '@/components/CustomSelect';
import CustomButton from '@/components/CustomButton';
import GenericModal from '@/components/modals/GenericModal';
import InputText from '@/components/InputText';
import TextArea from '@/components/TextArea';
import axios from '@/api/axios';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';

export default {
  name: 'GenerateExpertiseConclusion',
  components: { CustomSelect, CustomButton, GenericModal, InputText, TextArea },
  watch: {
    open(open) {
      if (open) {
        this.vuelidate.form.$reset();
      }
    },
    organization(organization) {
      this.form.organization.name = organization;
    },
    order(order) {
      this.form.order.createdAt = order.createdAt;
      this.form.order.number = order.number;
    },
    expert(expert) {
      this.form.expert = expert;
    },
    programs(programs) {
      this.form.specialities = programs.map(function (program) {
        const educationLevel = program.educationLevel ?? '';
        let date;
        let order;
        switch (educationLevel) {
          case 'Основное общее образование':
          case 'Среднее общее образование':
          case 'Начальное общее образование':
            date = new Date('April 14, 2023 03:24:00');
            break;
          case 'Среднее профессиональное образование' :
          case 'Среднее профессиональное образование – программы подготовки специалистов среднего звена' :
          case 'Среднее профессиональное образование (базовая подготовка)' :
          case 'Среднее профессиональное образование по программам подготовки специалистов среднего звена' :
          case 'Среднее профессиональное образование по программам подготовки квалифицированных рабочих, служащих' :
            date = new Date('April 14, 2023 03:24:00');
            break;
          case 'Высшее образование - бакалавриат':
          case 'Высшее образование - магистратура':
          case 'Высшее образование - специалитет':
          case 'Высшее образование - подготовка кадров высшей квалификации':
          case 'Высшее образование – подготовка кадров высшей квалификации по программам подготовки научно-педагогических кадров в аспирантуре':
          case 'Высшее образование – подготовка кадров высшей квалификации по программам подготовки научно-педагогических кадров в аспирантуре ':
            date = new Date('April 18, 2023 03:24:00');
            break;
          default:
            date = null;
            break;
        }
        switch (educationLevel) {
          case 'Основное общее образование':
          case 'Среднее общее образование':
          case 'Начальное общее образование':
            order = '271'
            break;
          case 'Среднее профессиональное образование' :
          case 'Среднее профессиональное образование – программы подготовки специалистов среднего звена' :
          case 'Среднее профессиональное образование (базовая подготовка)' :
          case 'Среднее профессиональное образование по программам подготовки специалистов среднего звена' :
          case 'Среднее профессиональное образование по программам подготовки квалифицированных рабочих, служащих' :
          case 'Высшее образование – подготовка кадров высшей квалификации по программам подготовки научно-педагогических кадров в аспирантуре':
          case 'Высшее образование – подготовка кадров высшей квалификации по программам подготовки научно-педагогических кадров в аспирантуре ':
            order = '272'
            break;
          case 'Высшее образование - бакалавриат':
          case 'Высшее образование - магистратура':
          case 'Высшее образование - специалитет':
          case 'Высшее образование - подготовка кадров высшей квалификации':
            order = '409';
            break;
          default:
            order = '';
            break;
        }
        return {
          code: program.code,
          name: program.name,
          educationLevel: educationLevel,
          egs: {
            code: program.egsCode,
            name: program.egsName,
          },
          accreditations: {
            createdAt: date,
            number: order,
            compliance: 'Соответствует'
          },
        }
      });
    },
  },
  setup() {
    return {vuelidate: useVuelidate()};
  },
  data() {
    return {
      form: {
        expertiseId: this.expertiseId,
        createdAt: new Date(),
        order: {
          type: 'Распоряжение',
          createdAt: null,
          number: null,
          issuedBy: 'Федеральная служба по надзору в сфере образования и науки',
        },
        organization: {
          name: null,
        },
        specialities: [],
        expert: this.expert,
      },
    };
  },
  validations() {
    return {
      form: {
        createdAt: {required},
        specialities: this.form.specialities.map(() => {
          return {
            accreditations: {
              createdAt: {required},
              number: {required},
              compliance: {required},
            },
          };
        }),
      },
    };
  },
  methods: {
    generateConclusion() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }
      axios.post('templates/conclusion', this.form, {responseType: 'blob'})
          .then(this.downloadFile)
          .then(() => this.$emit('close'))
          .catch(() => window.alert('Не удалось сформировать заключение. Попробуйте, пожалуйста, позже!'));
    },
    downloadFile(response) {
      const blob = response.data;
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);

      let objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = 'заключение.docx';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    },
  },
  props: {
    open: Boolean,
    organization: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    accreditations: {
      type: Object,
      required: true,
    },
    expert: {
      type: String,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
    expertiseId: String,
  },
  emits: ['close']
};
</script>
